<template>
  <b-card>
      <!-- Form -->
      <b-form
        class="p-2"
      >
      <b-row class="mb-2"><h3>Detail Laporan</h3></b-row>
        <b-row>
          <b-col
            xl="6"
          >
            <b-form-group
              label="Status"
              label-for="status"
            >
              <b-form-input
                id="status"
                autofocus
                trim
                placeholder=""
                :disabled="true"
                v-model="localData.status"
              />
            </b-form-group>

            <b-form-group
              label="No Penerimaan"
              label-for="no_penerimaan"
            >
              <b-form-input
                id="no_penerimaan"
                autofocus
                trim
                placeholder=""
                :disabled="true"
                v-model="localData.no_penerimaan"
              />
            </b-form-group>

            <b-form-group
              label="Tanggal Penerimaan"
              label-for="tgl_penerimaan"
            >
              <b-form-input
                id="tgl_penerimaan"
                autofocus
                trim
                placeholder=""
                :disabled="true"
                v-model="localData.tgl_penerimaan"
              />
            </b-form-group>
            <b-form-group
              label="Nomor Registrasi"
              label-for="no_register"
            >
              <b-form-input
                id="no_register"
                autofocus
                trim
                placeholder=""
                :disabled="true"
                v-model="localData.no_register"
              />
            </b-form-group>
            <b-form-group
              label="Tanggal Registrasi"
              label-for="tgl_register"
            >
              <b-form-input
                id="tgl_register"
                autofocus
                trim
                placeholder=""
                :disabled="true"
                v-model="localData.tgl_register"
              />
            </b-form-group>
            <b-form-group
              label="Nomor Surat"
              label-for="no_surat"
            >
              <b-form-input
                id="no_surat"
                autofocus
                trim
                placeholder=""
                :disabled="true"
                v-model="localData.no_surat"
              />
            </b-form-group>
            <b-form-group
              label="Perihal"
              label-for="perihal"
            >
              <b-form-textarea
                id="perihal"
                placeholder="Enter metadata JSON Format"
                rows="3"
                max-rows="8"
                no-auto-shrink
                v-model="localData.perihal"
                :disabled="true"
              >
              </b-form-textarea >
            </b-form-group>

             <!-- <b-form-group
              label="Deskripsi"
              label-for="deskripsi"
            >
              <b-form-input
                id="deskripsi"
                autofocus
                trim
                placeholder=""
              />
            </b-form-group>

              <b-form-group
              label="Metadata"
              label-for="metadata"
            >
              <b-form-textarea
                id="metadata"
                placeholder="Enter metadata JSON Format"
                rows="3"
                max-rows="8"
                no-auto-shrink
              />
            </b-form-group>

             <b-form-group
              label="Tipe"
              label-for="tipe"
            >
              <b-form-input
                id="tipe"
                autofocus
                trim
                placeholder=""
              />
            </b-form-group> -->

          </b-col>
           <b-col
            xl="6"
          >
          <b-form-group
              label="Jenis Penyampaian"
              label-for="jenis_penyampaian"
            >
              <b-form-input
                id="jenis_penyampaian"
                autofocus
                trim
                placeholder=""
                :disabled="true"
                v-model="localData.jenis_penyampaian"
              />
            </b-form-group>
            <b-form-group
              label="Diterima Melalui"
              label-for="diterima_melalui"
            >
              <b-form-input
                id="diterima_melalui"
                autofocus
                trim
                placeholder=""
                :disabled="true"
                v-model="localData.diterima_melalui"
              />
            </b-form-group>
            <b-form-group
              label="Jenis Perkara"
              label-for="jenis_perkara"
            >
              <b-form-input
                id="jenis_perkara"
                autofocus
                trim
                placeholder=""
                :disabled="true"
                v-model="localData.jenis_perkara"
              />
            </b-form-group>
             <b-form-group
              label="Lingkungan Peradilan"
              label-for="lingkungan_peradilan"
            >
              <b-form-input
                id="lingkungan_peradilan"
                autofocus
                trim
                placeholder=""
                :disabled="true"
                v-model="localData.lingkungan_peradilan"
              />
            </b-form-group>
             <b-form-group
              label="Lokasi Laporan"
              label-for="kota"
            >
              <b-form-input
                id="kota"
                autofocus
                trim
                placeholder=""
                :disabled="true"
                v-model="localData.kota"
              />
            </b-form-group>
            <b-form-group
              label="Nomor Putusan"
              label-for="no_putusan"
            >
              <b-form-input
                id="no_putusan"
                autofocus
                trim
                placeholder=""
                :disabled="true"
                v-model="localData.no_putusan"
              />
            </b-form-group>
            <b-form-group
              label="Keterangan"
              label-for="keterangan"
            >
              <b-form-input
                id="keterangan"
                autofocus
                trim
                placeholder=""
                :disabled="true"
                v-model="localData.keterangan"
              />
            </b-form-group>

           </b-col>
        </b-row>

        <!-- Form Actions -->
        <div class="d-flex mt-2" v-if="localData.status_investigasi === null">
          <modal-verifikasi :localData="localData" />
        </div>
        <div class="d-flex mt-2" v-else>
          <b-button class="btn mr-1" variant="outline-primary" v-b-modal.modal_confirm_verifikasi>Teruskan Ke Verifikasi</b-button>
          <b-button class="btn" variant="outline-primary" v-b-modal.modal_confirm_arsip>Arsipkan</b-button>
        </div>

        <!-- modal teruskan ke verifikasi -->
        <div>
          <b-modal id="modal_confirm_verifikasi" title="Konfirmasi" centered hide-footer>
            <div class="d-flex justify-content-center" >
              <h4 class="my-4" style="text-align: center;">Apakah anda yakin teruskan ke verifikasi?</h4>
            </div>
            <div class="d-flex justify-content-end">
              <b-form-group>
                <b-button class="btn mr-1" variant="primary" @click="update('verifikasi')">Ya</b-button>
              </b-form-group>
              <b-form-group>
                <b-button class="btn" variant="outline-primary" @click="close" ref="btnClose">Tidak</b-button>
              </b-form-group>
            </div>
          </b-modal>
        </div>

        <!-- modal arsipkan -->
        <div>
          <b-modal id="modal_confirm_arsip" title="Konfirmasi" centered hide-footer>
            <div class="d-flex justify-content-center">
              <h4 class="my-4" style="text-align: center;">Apakah anda yakin laporan diarsipkan?</h4>
            </div>
            <div class="d-flex justify-content-end">
              <b-form-group>
                <b-button class="btn mr-1" variant="primary" @click="update('arsip')">Ya</b-button>
              </b-form-group>
              <b-form-group>
                <b-button class="btn" variant="outline-primary" @click="close" ref="btnClose">Tidak</b-button>
              </b-form-group>
            </div>
          </b-modal>
        </div>

      </b-form>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol, BBadge,
  BForm, BFormGroup, BFormInput, BFormInvalidFeedback,
  BFormSpinbutton, BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import { required, alphaNum, email } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import { avatarText } from '@core/utils/filter'
import useList from '@/connection-api/jenis-dokumen/list'
import moment from 'moment'
import flatPickr from 'vue-flatpickr-component'
import '@core/scss/vue/libs/vue-flatpicker.scss'
import ModalVerifikasi from './ModalVerifikasi.vue'
import {
  updateVerifikasi,
} from '@/connection-api/master'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    ModalVerifikasi,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      configs: {
        basic: {},
        wrap: {
          wrap: true,
          altFormat: 'M j, Y',
          altInput: true,
          dateFormat: 'Y-m-d',
        },
      },
    }
  },
  props: {
    localData: {},
  },
  // mounted() {
  //   this.loadProp()
  // },
  setup() {
    const { resolveUserRoleVariant, resolveUserStatusVariant } = useList()
    const blankUserData = {
      // nama: this.rowData.data.nama,
      // wilayah: this.rowData.data.wilayah,
      // upt: this.rowData.data.upt,
      // jenis_asset_id: this.rowData.data.jenis_asset_id,
      // status_asset_id: this.rowData.data.status_asset_id,
      // tanggal_aktif: this.rowData.data.tanggal_aktif,
      // luas_tanah: this.rowData.data.luas_tanah,
      // luas_bangunan: this.rowData.data.luas_bangunan,
      // harga_perolehan: this.rowData.data.harga_perolehan,
      // id: this.rowdata.data.id,
    }
    // alert(this.userData.nama)
    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))

    const onSubmit = () => {
      store.dispatch('jenis-dokumen/updateData', this.rowData.data)
        .then(response => {
        })
        .catch(error => {

        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    return {
      avatarText,
      resolveUserRoleVariant,
      resolveUserStatusVariant,

      userData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
  methods: {
    async update(params) {
      switch (params) {
        case 'verifikasi':
          this.localData.jenis_cta = "verifikasi";
          break;
        case 'arsip':
          this.localData.jenis_cta = 'arsip';
          break;
      }
      
      this.localData.laporan_id = this.$route.params.laporan_id
      const response = await updateVerifikasi(this.localData)
      if(response.data.success === true)
          {
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Verifikasi',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: response.data.message,
                },
              })
              this.$emit('refetchData')
              this.$router.push({name: 'verifikasi'})
              this.close()
          }
          else
          {
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Verifikasi',
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: response.data.message,
                },
              })
              this.error = response.data.error
          }
    },
    async updateData() {
      store.dispatch('jenis-dokumen/updateData', this.rowData.data)
        .then(response => {
          this.makeToast('success', 'Simpan Data Jenis Dokumen Berhasil Dilakukan')
        })
        .catch(error => {
          // console.log(error)
        })
    },
    formatPrice(value) {
      const val = (value / 1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    formatDate(value, format) {
      return moment(String(value)).format(format)
    },
    makeToast(variant = null, message = null) {
      this.$bvToast.toast(message, {
        // title: `Variant ${variant || 'default'}`,
        title: 'Biodata',
        variant,
        solid: true,
        autoHideDelay: 10000,
      })
    },
  },
}
</script>

<style>

</style>
