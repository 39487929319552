<template>
  <component :is="localData === undefined ? 'div' : 'div'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="localData === undefined"
    >
      <h4 class="alert-heading">
        Gagal menemukan data
      </h4>
      <div class="alert-body">
        Tidak ada data ditemukan, Cek
        <b-link         class="alert-link"
          :to="{ name: 'investigasi-advokasi'}"
        >
          Disini
        </b-link>
        untuk melihat data lainnya.
      </div>
    </b-alert>

    <template>
      <!-- First Row -->
      <b-row>
        <b-col
          cols="12"
          xl="12"
          lg="12"
          md="12"
        >
          <top-form :localData="localData"/>
          <b-tabs
            content-class="col-12 col-md-12 mt-1 mt-md-0"
            pills
            nav-wrapper-class="col-md-12 col-12"
            nav-class="nav-left"
          >
            <!-- Pelapor -->
            <b-tab>
              <template #title>
                <feather-icon
                  icon="FileTextIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">Pelapor</span>
              </template>
              <pelapor/>
            </b-tab>
            <!--/ Pelapor -->
            <!-- Kuasa Pelapor -->
            <b-tab>
              <template #title>
                <feather-icon
                  icon="FileTextIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">Kuasa Pelapor</span>
              </template>
              <kuasa-pelapor/>
            </b-tab>
            <!--/ Kuasa Pelapor -->
            <!-- Terlapor -->
            <b-tab>
              <template #title>
                <feather-icon
                  icon="FileTextIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">Terlapor</span>
              </template>
              <terlapor/>
            </b-tab>
            <!--/ Terlapor -->
             <!-- Kelengkapan Data -->
            <b-tab>
              <template #title>
                <feather-icon
                  icon="FileTextIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">Kelengkapan Data</span>
              </template>
              <kelengkapan-data/>
            </b-tab>
            <!--/ Kelengkapan Data -->
          <!-- Surat -->
            <b-tab>
              <template #title>
                <feather-icon
                  icon="FileTextIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">Surat</span>
              </template>
              <surat />
            </b-tab>
            <!--/ Surat -->
            <!-- Dokumen -->
            <b-tab>
              <template #title>
                <feather-icon
                  icon="FileTextIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">Dokumen</span>
              </template>
              <dokumen />
            </b-tab>
            <!--/ Dokumen -->
            <!-- Berita Acara -->
            <b-tab>
              <template #title>
                <feather-icon
                  icon="FileTextIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">Berita Acara</span>
              </template>
              <berita-acara/>
            </b-tab>
            <!--/ Berita Acara -->
            <!-- Dokumen MKH -->
            <b-tab>
              <template #title>
                <feather-icon
                  icon="FileTextIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">Dokumen MKH</span>
              </template>
              <!-- <terlapor/> -->
            </b-tab>
            <!--/ Dokumen MKH -->
            <!-- Pemantauan -->
            <!-- <b-tab>
              <template #title>
                <feather-icon
                  icon="FileTextIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">Pemantauan</span>
              </template>
              <terlapor/>
            </b-tab> -->
            <!--/ Pemantauan -->
            <!-- Logs -->
            <b-tab>
              <template #title>
                <feather-icon
                  icon="FileTextIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">Logs</span>
              </template>
              <terlapor/>
            </b-tab>
            <!--/ Logs -->
          </b-tabs>
        </b-col>
      </b-row>

      <!-- <b-row>
        <b-col
          cols="12"
          lg="6"
        >
          <user-view-user-timeline-card />
        </b-col>
        <b-col
          cols="12"
          lg="6"
        >
          <user-view-user-permissions-card />
        </b-col>
      </b-row> -->

    </template>

  </component>
</template>

<script>
import {
  BRow, BCol, BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import {
  // getJenisUPT,
  // getKanwil,
  getInvestigasiAdvokasiByLaporanId
} from '@/connection-api/master'
import storeModule from '@/connection-api/verifikasi/storeModule'
import Pelapor from '@/views/pelapor/list/List.vue'
import KuasaPelapor from '@/views/kuasa-pelapor/list/List.vue'
import Terlapor from '@/views/terlapor/list/List.vue'
import TopForm from './TopForm.vue'
import KelengkapanData from '@/views/kelengkapan-data/list/List.vue'
import Surat from '@/views/surat/list/List.vue'
import Dokumen from '@/views/dokumen/list/List.vue'
import BeritaAcara from '@/views/berita-acara/list/List.vue'

export default {
  components: {
    BRow,
    BCol,
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    Pelapor,
    KuasaPelapor,
    Terlapor,
    TopForm,
    KelengkapanData,
    Surat,
    Dokumen,
    BeritaAcara,
  },
  data() {
    return {
      localData: {},
      // jenisUptOptions: {
      //   selected: 'id',
      //   options: [
      //     { value: null, label: '--Pilih Jenis UPT --' },
      //   ],
      // },
      // kanwilOptions: {
      //   selected: 'id',
      //   options: [
      //     { value: null, label: '--Pilih Jenis Kanwil --' },
      //   ],
      // },
      // uptOptions: {
      //   selected: 'id',
      //   options: [
      //     { value: null, label: '--Pilih UPT --' },
      //   ],
      // },
      // jenisAssetOptions: {
      //   selected: 'id',
      //   options: [
      //     { value: null, label: '--Pilih Jenis Asset --' },
      //   ],
      // },
      // statusAssetOptions: {
      //   selected: 'id',
      //   options: [
      //     { value: null, label: '--Pilih Status Asset --' },
      //   ],
      // },
      // assetId: null,
      // dataMasterJenisDokumenLainnya: null,
      // dataMasterJenisDokumenFoto: null,
    }
  },
  mounted() {
    this.reloadData()
  },
  methods: {
    async reloadData() {
      try {
        const responseInvestigasiAdvokasi = await getInvestigasiAdvokasiByLaporanId(this.$route.params.laporan_id)
        if(responseInvestigasiAdvokasi.data.success === true)
        {
          this.localData = responseInvestigasiAdvokasi.data.data
        }
        else
        {
          this.localData = undefined
        }
      } catch (error) {
        this.localData = undefined
      }
      
      

      // const jenisDokumenFoto = await getAllJenisDokumen(1)
      // this.dataMasterJenisDokumenFoto = jenisDokumenFoto.data

      // const responseJenisUptOptions = await getJenisUPT()
      // this.mappingSelectOption(responseJenisUptOptions.data, this.jenisUptOptions.options)

      // const responseKanwilOptions = await getKanwil()
      // this.mappingSelectOptionIds(responseKanwilOptions.data, this.kanwilOptions.options)

      // const responsejenisAssetOptions = await getJenisAsset()
      // this.mappingSelectOption(responsejenisAssetOptions.data, this.jenisAssetOptions.options)

      // const responseStatusAssetOptions = await getStatusAsset()
      // this.mappingSelectOption(responseStatusAssetOptions.data, this.statusAssetOptions.options)

      // this.reloadUPTOptions(this.localData.data.wilayah)
    },
    // async reloadUPTOptions(kanwilId) {
    //   this.uptFilter = null
    //   this.uptOptions = {
    //     selected: 'id',
    //     options: [
    //       { value: null, label: '--Pilih UPT --' },
    //     ],
    //   }
    //   const responseUptOptions = await getUPT(kanwilId)
    //   this.mappingSelectOptionIds(responseUptOptions.data, this.uptOptions.options)
    // },
    mappingSelectOption(master, name) {
      if (master.length > 0) {
        master.forEach(element => {
          name.push({
            label: element.nama,
            value: element.id,
          })
        })
      }
    },
    mappingSelectOptionIds(master, name) {
      if (master.length > 0) {
        master.forEach(element => {
          name.push({
            label: element.nama,
            value: element.ids,
          })
        })
      }
    },
  },
}
</script>

<style>

</style>
